import { saveSeon } from '@/api/risks-report';

export enum SEON_CONFIG {
  PUBLIC_KEY = 'public_key',
  SESSION_ID = 'session_id',
  AUDIO_FINGERPRINT = 'audio_fingerprint',
  CANVAS_FINGERPRINT = 'canvas_fingerprint',
  WEBGL_FINGERPRINT = 'webgl_fingerprint',
  ON_SUCCESS = 'onSuccess',
  ON_ERROR = 'onError'
}

export interface SeonConfig {
  [SEON_CONFIG.PUBLIC_KEY]: string;
  [SEON_CONFIG.SESSION_ID]: string;
  [SEON_CONFIG.AUDIO_FINGERPRINT]?: boolean;
  [SEON_CONFIG.CANVAS_FINGERPRINT]?: boolean;
  [SEON_CONFIG.WEBGL_FINGERPRINT]?: boolean;
  [SEON_CONFIG.ON_SUCCESS]: (message: string) => void;
  [SEON_CONFIG.ON_ERROR]: (message: string) => void;
}

export interface Seon {
  config: (args: SeonConfig) => void;
  getBase64Session: () => Promise<string>;
  generateUUID: () => string;
}

function generateUUID(): string {
  let timestamp = new Date().getTime();
  const pattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  return pattern.replace(/[xy]/g, c => {
    const r = (timestamp + Math.random() * 16) % 16 | 0;
    timestamp = Math.floor(timestamp / 16);
    const result = c === 'x' ? r : (r & 0x3) | 0x8;
    return result.toString(16);
  });
}

export const SeonService = new (class {
  initConfig() {
    window.seon.config({
      [SEON_CONFIG.PUBLIC_KEY]: process.env.VUE_APP_BASE_SEON_PUBLIC_KEY,
      [SEON_CONFIG.SESSION_ID]: generateUUID(),
      [SEON_CONFIG.ON_SUCCESS]: message => {
        console.info('[Seon]:', message);
      },
      [SEON_CONFIG.ON_ERROR]: message => {
        console.error('[Seon]:', message);
      }
    });
  }

  getSeonSessionHash(): Promise<string> {
    try {
      this.initConfig();
      return window.seon.getBase64Session();
    } catch (e) {
      return Promise.reject();
    }
  }

  async init() {
    try {
      const session = await this.getSeonSessionHash();
      await saveSeon({ session });
    } catch (e) {
      console.error(e);
    }
  }
})();
