
import { Component, Mixins } from 'vue-property-decorator';
import { financeMinFiveDigit, isDatePast, required } from '@/components/registration/settings/rules';
import { companyMask, currencyMask } from '@/components/registration/settings/mask';
import LmsInput from '@/components/registration/FieldInput.vue';
import LmsSelect from '@/components/registration/FieldSelect.vue';
import LmsForm from '@/components/registration/Form.vue';
import { ContentService } from '@/services/content';
import { SECTIONS } from '@/store/modules/models/content';
import { REGISTRATION_ROUTES } from '@/router/routes_type';
import { addStep, buildUiStepState } from '@/api/progress';
import { UI_CLIENT_FLOW_STEP_NAME } from '@/api/models/progress';
import LmsDateInput from '@/components/registration/FieldDateInput.vue';
import { moneyPattern } from '@/components/registration/settings/pattern';
import Faq from '@/components/Faq.vue';
import LmsMultiInput from '@/components/registration/FieldMultiUpload.vue';
import FinancesMixin from '@/views/registration/AdditionalInfo/FinancesMixin.vue';

const TRIGGERED_EMPLOYMENT_STATUS = [
  'state-employee',
  'pensioner-company',
  'pensioner-individual-entrepreneur',
  'hired-employee',
  'maternity-leave',
  'individual-entrepreneur'
];

@Component({
  name: 'Finances',
  computed: {
    SECTIONS_FAQ() {
      return SECTIONS.FINANCES_FAQ;
    }
  },
  components: {
    LmsMultiInput,
    Faq,
    LmsDateInput,
    LmsForm,
    LmsInput,
    LmsSelect
  },
  methods: {
    moneyPattern,
    currencyMask,
    financeMinFiveDigit,
    isDatePast,
    companyMask,
    required
  }
})
export default class Finances extends Mixins(FinancesMixin) {
  get employmentStatusOptions() {
    return () => ContentService.sortedOptions(SECTIONS.EMPLOYMENT_STATUS);
  }

  get positionOptions() {
    return () => ContentService.sortedOptions(SECTIONS.EMPLOYMENT_POSITION);
  }

  get industryOptions() {
    return () => ContentService.optionsList(SECTIONS.INDUSTRY);
  }

  get visibility() {
    return TRIGGERED_EMPLOYMENT_STATUS.includes(this.financesData.workInfo.employmentStatus);
  }

  async submitForm() {
    this.$emit('update:loading', true);
    try {
      await this.updateFinancesData(REGISTRATION_ROUTES.ADDITIONAL_INFO);
      await addStep(buildUiStepState(UI_CLIENT_FLOW_STEP_NAME.FINANCES));
      setTimeout(() => {
        this.$emit('submit');
        this.$emit('update:loading', false);
      }, 1000);
    } catch (error) {
      this.$emit('update:loading', false);
    }
  }
}
