import { ClientCreatePayload } from '@/api/models/client';
import { marketingMetrics } from '@/utils/marketing';
import { client } from '@/store/modules/client';
import { VERIFICATION_TYPE } from '@/api/models/verification';
import { VerificationControllerClass } from '@/services/classes/verificationControllerClass';

class RegistrationServiceVerificationClass extends VerificationControllerClass {
  data = { username: '', email: '', mobilePhone: '' };

  get getHiddenIdentity() {
    const numbersToHide = this.data.mobilePhone.substring(5, this.data.mobilePhone.length - 3);
    const hideMask = '*'.repeat(numbersToHide.length);

    return this.data.mobilePhone.replace(numbersToHide, hideMask);
  }

  initVerification() {
    return this.createVerification({
      identity: this.data.mobilePhone,
      type: VERIFICATION_TYPE.MOBILE_CONFIRMATION
    });
  }

  async createClient(consents: Record<string, boolean>) {
    if (!this.verificationStore) {
      return;
    }
    const payload: ClientCreatePayload = {
      email: this.data.email,
      username: this.data.username,
      mobilePhone: this.data.mobilePhone,
      mobilePhoneVerificationId: this.verificationStore.verificationId,
      password: '',
      companyId: process.env.VUE_APP_BASE_COMPANY_ID,
      consents
    };

    if (Object.keys(marketingMetrics()).length) {
      payload.additionalInfo = {
        data: {
          marketing: marketingMetrics()
        }
      };
    }

    await client.initCreateClient(payload);
    this.verificationStore.removeVerification();
  }
}
export const RegistrationService = new RegistrationServiceVerificationClass();
