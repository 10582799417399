
import { UploadSource } from '@/api/models/document';
import { Component, Mixins } from 'vue-property-decorator';
import Field from '@/components/registration/Field.vue';
import { FileMaxSize, validateFileSize } from '@/utils/file';
import { FORM_ERRORS } from '@/components/registration/settings/rules';

const MAX_FILES = 5;

@Component({
  name: 'LmsMultiInput'
})
export default class MultiImageUpload extends Mixins(Field) {
  focused = false;
  value: UploadSource[] = [];

  private notifyError(message: string) {
    this.error = message;
  }

  get limited() {
    return this.value.length === MAX_FILES;
  }

  onError() {
    this.notifyError(FORM_ERRORS.BANK_STATEMENT_UPLOAD_ERROR);
  }

  onFocus() {
    this.focused = true;
  }

  onBlur() {
    this.focused = false;
  }

  limitReached(files: any) {
    let uploadSources = (Array.from(files) as File[]).map(
      (file: File): UploadSource => {
        return {
          raw: file,
          name: file.name,
          percentage: 0,
          status: 'success',
          uid: 0,
          size: file.size
        };
      }
    );

    uploadSources = uploadSources.filter((file: UploadSource) => {
      if (!validateFileSize(file.raw, FileMaxSize.BANK_STATEMENT)) {
        this.notifyError(FORM_ERRORS.BANK_STATEMENT_FILE_SIZE);
        return false;
      }

      return true;
    });

    if (uploadSources.length > MAX_FILES) {
      this.value = uploadSources.slice(0, MAX_FILES);
      this.notifyError(FORM_ERRORS.BANK_STATEMENT_LIMIT_REACHED);
    } else {
      this.value = uploadSources;
    }

    this.$emit('input', this.value);
  }

  get maxFiles() {
    return MAX_FILES;
  }

  onRemove(file: UploadSource, fileList: UploadSource[]) {
    this.error = '';

    this.value = fileList;
    this.$emit('input', this.value);
  }

  onChange(file: UploadSource, fileList: UploadSource[]) {
    this.error = '';
    if (!validateFileSize(file.raw, FileMaxSize.BANK_STATEMENT)) {
      this.notifyError(FORM_ERRORS.BANK_STATEMENT_FILE_SIZE);
      fileList.pop();
      this.value = fileList;
    } else {
      this.value = fileList;
    }

    this.$emit('input', this.value);
  }
}
