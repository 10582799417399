
import { DOCUMENT_TYPES, UploadSource } from '@/api/models/document';

import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { FileMaxSize, FileType, validateFileSize, validateFileType } from '@/utils/file';

import { resizeImageToBlob } from '@/components/registration/settings/tools';
import { MAX_HEIGHT, MAX_WIDTH, UPLOAD_STATUS } from '@/components/DocumentImageInput/DocumentImageUpload.config';
import LmsDocumentImagePreviewPopup from '@/components/DocumentImageInput/DocumentImagePreviewPopup.vue';
import LmsDocumentImagePreview from '@/components/DocumentImageInput/DocumentImagePreview.vue';

type UploadError = Error & {
  data: {
    detail: string;
    title: string;
  };
};

@Component({
  name: 'LmsDocumentImageUpload',
  components: { LmsDocumentImagePreview, LmsDocumentImagePreviewPopup }
})
export default class DocumentImageUploadComponent extends Vue {
  @Prop() private placeholder: string;
  @Prop({ required: true }) private documentType!: DOCUMENT_TYPES;
  @Prop({ required: true }) private progress: number;

  uploadStatus: UPLOAD_STATUS = UPLOAD_STATUS.INIT;

  thumbBlob = '';

  errorMessage = '';

  get isInit() {
    return this.uploadStatus === UPLOAD_STATUS.INIT;
  }

  get isUploading() {
    return !this.isInit;
  }

  @Emit('remove')
  async handleRemove() {
    this.uploadStatus = UPLOAD_STATUS.INIT;
  }

  handleError(error: UploadError) {
    this.uploadStatus = UPLOAD_STATUS.ERROR;
    this.notifyError(
      error?.message || error?.data?.detail || error?.data?.title || 'NOTIFICATION.DOCUMENT_UPLOAD.ERROR'
    );
  }

  private notifyError(message: string) {
    this.uploadStatus = UPLOAD_STATUS.ERROR;
    this.errorMessage = this.$t(message).toString();
    this.$notify.error(this.errorMessage);
    // this.reset();
  }

  async handleChange(source: UploadSource) {
    this.uploadStatus = UPLOAD_STATUS.STARTED;

    if (!validateFileType(source.raw, FileType.IMAGE)) {
      this.notifyError('NOTIFICATION.IMAGE_UPLOAD.INCORRECT_IMAGE_FILE_FORMAT');
    } else if (!validateFileSize(source.raw, FileMaxSize.IMAGE)) {
      this.notifyError('NOTIFICATION.IMAGE_UPLOAD.INCORRECT_IMAGE_FILE_SIZE');
    } else {
      await this.handleImageUpload(source);
    }
  }

  setThumb(blob: File) {
    this.thumbBlob = URL.createObjectURL(blob);
  }

  // try to resize image locally
  async resizeRawImage(raw: File) {
    const { type, lastModified, name } = raw;
    const blobImage = await resizeImageToBlob(raw, MAX_WIDTH, MAX_HEIGHT);
    return new File([blobImage], name, { type, lastModified });
  }

  @Emit('upload')
  async handleImageUpload({ raw }: UploadSource) {
    try {
      const file = await this.resizeRawImage(raw);
      const media = { type: this.documentType, file };

      Vue.nextTick().then(() => {
        this.setThumb(file);
      });

      return media;
    } catch (error) {
      this.handleError(error);
    }
  }
}
